let productIds, latest_product_cookies;

function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

function checkCookie() {
    const latest_product_cookies = getCookie('latest_product_cookies');
    if( latest_product_cookies != null && latest_product_cookies != undefined && latest_product_cookies != '' ) {
        productIds = JSON.parse(latest_product_cookies);
    } else {
        productIds = [];
    }
}

document.addEventListener("DOMContentLoaded", checkCookie);

function setCookie(cName, productId, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();    
    productIds.push(productId);
    document.cookie = cName + "=" + JSON.stringify(productIds) + "; " + expires + "; path=/";
}

const products = document.querySelectorAll('li.product');
products.forEach(product => product.addEventListener('click', function(){
    const cookieId = product.querySelector('.favourite-product-checkbox');
    setCookie('latest_product_cookies', cookieId.id, 30);
}));
