document.addEventListener('click', (e) => {
    if( e.target.matches('.faq-question') ) {
        const answer = e.target.nextElementSibling;
        if( answer.classList.contains('active') ) {
            answer.classList.remove('active');
            e.target.classList.remove('active');
        } else {    
            answer.classList.add('active');
            e.target.classList.add('active');
        }
    }
});