// Include all files here.
require('bootstrap')
import './menu'
import './global'
import './mega-menu'
import './search'
import './video'
import './coupons'
import './ajax-load-posts'
// import './ajax-load-products'
import './ajax-add-to-cart'
import './side-scroll'
import './category-filters'
import './blocks/block-menu'
import './blocks/block-faq'
import './blocks/block-img-carousel'
import './components/sliders'
import './components/request-product'
import './components/tabs'
import './components/favourites'
import './components/latest-products'
import './components/catalogues'
import './woocommerce'

// Scss
import './../scss/style.scss'
