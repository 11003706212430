const searchInput = document.querySelectorAll('.filter-search');

function displayMatches(e) {
    if( e.target.matches('.filter-search') ){
        var filter, li, filterName, i, searchValue;
        let input = e.target;
        const clearSearchInput = input.nextElementSibling;
        let filterWrapper = input.parentElement.parentElement;
        let filterContainer = filterWrapper.querySelector('.filter-container');
        filter = input.value.toUpperCase();
        
        li = filterContainer.querySelectorAll('.filter-list-item');
        for (i = 0; i < li.length; i++) {
            filterName = li[i].querySelectorAll('.filter-item')[0];
            searchValue = filterName.textContent || filterName.innerText;
            if (searchValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = '';
                clearSearchInput.classList.remove('show');
            } else {
                li[i].style.display = 'none';
                clearSearchInput.classList.add('show');
            }
        }
    }
}

if (searchInput) {
    searchInput.forEach(input => {
        input.addEventListener('keyup', displayMatches);
    });
}

const clearSearchInput = document.querySelectorAll('.clear-search-field');
clearSearchInput.forEach(clearBtn => clearBtn.addEventListener('click', function(){
    const input = clearBtn.previousElementSibling;
    const filterWrapper = clearBtn.parentElement.parentElement;
    const lis = filterWrapper.querySelectorAll('.filter-list-item');

    lis.forEach(li => {
        li.style.display = '';
    });
    clearBtn.classList.remove('show');
    input.value = '';
    input.focus();
}));