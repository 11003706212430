/**
 * File mega-menu.js.
 */

import { closeSearch } from './search'
const lang = document.documentElement.lang
const langEnd = lang.substring(lang.indexOf('-', ''))
const currentLang = lang.replace(langEnd, '')

const megaItems = document.querySelectorAll('#lw-primary-menu .mega-item')

if (megaItems) {
    function wrap(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el)
        wrapper.classList.add('mega-sub-menu')
        wrapper.appendChild(el)
    }

    megaItems.forEach((megaItem) => {
        let firstSubMenu
        if (megaItem.querySelector('.sub-menu')) {
            firstSubMenu = megaItem.querySelector('.sub-menu')
            wrap(firstSubMenu, document.createElement('div'))
            firstSubMenu.classList.remove('sub-menu')
            firstSubMenu.classList.add('lw-mega-menu')
        }
        megaItem.classList.remove('menu-item-has-children')
    })
}

const menuParentItems = document.querySelectorAll('.menu-item-has-children')
const allSubMenues = document.querySelectorAll('.mega-sub-menu .sub-menu, .lw-mega-menu')

document.addEventListener('DOMContentLoaded', function () {
    // LÄGGER TILL KLICKAT ELEMENT SOM TITEL
    menuParentItems.forEach((menuParentItem) => {
        const subMenu = menuParentItem.querySelector('.mega-sub-menu .sub-menu')
        const titleItem = `<li class="menu-title"><a href="${menuParentItem.children[0].href}">${menuParentItem.children[0].textContent}</a></li>`
        if (subMenu) {
            subMenu.insertAdjacentHTML('afterbegin', titleItem)
        }
    })

    // LÄGGER TILL EN BAKÅTKNAPP I UNDERMENYERNA (FÖR MOBIL)
    allSubMenues.forEach((subMenu) => {
        let backBtn
        if (currentLang === 'en') {
            backBtn = /*html*/ `<div class="sub-back">Back</div>`
        } else {
            backBtn = /*html*/ `<div class="sub-back">Tillbaka</div>`
        }
        subMenu.insertAdjacentHTML('afterbegin', backBtn)
    })

    // HAMBURGARMENYN
    const mainMenu = document.querySelector('#lw-primary-menu')
    const mainMenuClone = mainMenu.cloneNode(true)
    mainMenuClone.removeAttribute('id', false)
    mainMenuClone.classList.remove('desktop-menu')
    mainMenuClone.classList.add('mobile-menu', 'menu')
    const megaSubs = mainMenuClone.querySelectorAll('.mega-sub-menu')
    const megaItemMenus = mainMenuClone.querySelectorAll('[id^=menu-item-mega]')

    megaItemMenus.forEach((megaItemMenu) => {
        megaItemMenu.id += '-mobile'
    })

    megaSubs.forEach((megaSub) => {
        megaSub.remove()
    })
    document.querySelector('#lw-hamburger-menu').appendChild(mainMenuClone)
})

document.addEventListener('click', function (e) {
    if (e.target.matches('.mega-item-child')) {
        e.preventDefault()
        // closeSearch();
        const megaItemLink = e.target

        const activeItems = megaItemLink.parentNode.querySelectorAll(
            '.current-menu-parent, .current-menu-ancestor, .current-menu-item'
        )
        if (activeItems) {
            activeItems.forEach((activeItem) => activeItem.classList.add('active'))
        }
    }

    // ÖPPNAR UNDERMENYERNA I MEGAMENYN
    if (e.target.matches('.menu-item-has-children')) {
        const selectedMenuItem = e.target
        const parent = e.target.parentNode
        const menuItemsHasChildren = parent.querySelectorAll('.menu-item-has-children')

        if (selectedMenuItem.classList.contains('active')) {
            selectedMenuItem.classList.remove('active')
        } else {
            menuItemsHasChildren.forEach((menuItem) => menuItem.classList.remove('active'))
            selectedMenuItem.classList.add('active')
        }
    }

    // BACKAR I MEGAMENYN
    if (e.target.matches('.sub-back')) {
        const parent = e.target.parentNode.parentNode
        if (parent.classList.contains('active')) {
            parent.classList.remove('active')
        }
    }

    // STÄNGER MENYN VID KLICK PÅ OVERLAY
    if (e.target.matches('.close-hamburger-menu')) {
        document.body.classList.remove('mega-menu-active', 'hamburger-active')
        menuParentItems.forEach((menuParentItem) => {
            menuParentItem.classList.remove('active')
        })
    }

    const overlay = document.querySelector('.toggle-el-overlay')
    const targets = document.querySelectorAll('.toggle-el')
    targets.forEach((target) => {
        const withinBoundaries = e.composedPath().includes(target)
        if (!e.target.matches('#close-search-result') && withinBoundaries) {
            if (!target.classList.contains('active')) {
                target.classList.add('active')
                overlay.classList.remove('d-none')
            }
        } else {
            if (target.classList.contains('active')) {
                target.classList.remove('active')
            }
        }
    })

    if (e.target.classList.contains('lw-hamburger')) {
        document.querySelector('#lw-hamburger-menu').classList.add('d-flex')
    }

    if (e.target.matches('#lw-hamburger-menu li.mega-item')) {
        document.querySelector('#lw-hamburger-menu').classList.remove('d-flex')
    }

    if (e.target.classList.contains('toggle-el-overlay')) {
        e.target.parentNode.classList.remove('active')
        overlay.classList.add('d-none')
        document.querySelector('#lw-hamburger-menu').classList.remove('d-flex')
    }

    const primaryToggleEls = document.querySelectorAll('#lw-primary-menu .toggle-el')

    if (e.target.matches('.mobile-menu .toggle-el')) {
        const mobileToggleEl = e.target
        primaryToggleEls.forEach((toggleEl) => {
            if (toggleEl.dataset.id === mobileToggleEl.dataset.id) {
                toggleEl.click()
            }
        })
    }

    // STÄNGER MEGAMENYN VID KLICK PÅ FÖRSTA BACKKNAPPEN I MOBILLÄGE
    if (e.target.matches('.lw-mega-menu > .sub-back')) {
        e.target.parentNode.parentNode.parentNode.classList.remove('active')
        document.querySelector('#lw-hamburger-menu').classList.add('d-flex')
        return
    }
})
