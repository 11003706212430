(function($) {

const loadMorePosts = document.querySelector('.posts-wrapper #load-more-p');
var canBeLoaded = true, // this param allows to initiate the AJAX call only if necessary
    bottomOffset = 2500; // the distance (in px) from the page bottom when you want to load more posts
    const catLocation = window.location.href;
    $(window).on('scroll', function() {
        var ajaxData = {
            action: 'load_posts',
            security : lwGlobal.loadPosts,
            page : lwGlobal.current_page,
            query : lwGlobal.posts
        };

        
        if( loadMorePosts && $(document).scrollTop() > ( $(document).height() - bottomOffset ) && canBeLoaded == true ) {
            $.ajax({
                type:'POST',
                url : lwGlobal.adminUrl,
                data: ajaxData,
                beforeSend() {
                    $('#load-more-p').addClass('active');
                    $('#load-more-p .load-spinner').addClass('show');
                    canBeLoaded = false;
                    
                },
                success(result) {
                    if( result ) {
                        $('.load-spinner').removeClass('show');
                        $('.posts-loop').find('.post-item:last-of-type').after( result ); // where to insert posts
                        canBeLoaded = true; // the ajax is completed, now we can run it again
                        lwGlobal.current_page++;
                        console.log('0');
                        if ( lwGlobal.current_page === lwGlobal.max_page ) {
                            $('#load-more-p').remove();
                            console.log('1');
                        }
                        $('#load-more-p').removeClass('active');
                        // window.history.pushState({}, null, catLocation + 'page/' + lwGlobal.current_page);
                    } else {
                        $('#load-more-p').remove();
                    }
                }
            });
        }
    });


})(jQuery);