const couponWrapper = document.querySelector('.coupon-code-wrapper');
let orderId = document.querySelector('#order-id');
let orderIdsArray;


function getCookie(cName) {
    const name = `${cName}=`;
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

function setCookie(cName, orderId, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    const existsIndex = orderIdsArray.indexOf(orderId);

    if (existsIndex < 0) {
        orderIdsArray.push(orderId);
        document.cookie = `${cName}=${JSON.stringify(orderIdsArray)};${expires};path=/`;
    }
}

function checkCookie() {
    const orders_cookie = getCookie('gjs_orders_cookie');
    if( orders_cookie != null && orders_cookie != undefined && orders_cookie != '' ) {
        orderIdsArray = JSON.parse(orders_cookie);
    } else {
        orderIdsArray = [];
    }
    if(orderId) {
        setCookie('gjs_orders_cookie', JSON.parse(orderId.innerHTML), 30);
    } else {
        // Sätter alltid en kaka för att slippa kolla om kakan ens existerar
        setCookie('gjs_orders_cookie', JSON.parse(1000000000001), 30);
    }
}

document.addEventListener("DOMContentLoaded", checkCookie);