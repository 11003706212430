let checkboxIds, ids_cookie;
const favouriteNumber = document.querySelector('.fav-number');
const checkboxesInfavourite = document.querySelectorAll('#favourite-container .favourite-product-checkbox');
if(checkboxesInfavourite){
    checkboxesInfavourite.forEach(cb => cb.addEventListener('click', function() {
        const product = cb.parentNode.parentNode;
        product.classList.add('hide');
        setTimeout(() => {
            product.classList.add('hidden');
        }, 1500);
    }));
}
const favouriteNotis = document.querySelector('#favourites');
const deletefavourite = document.querySelector('#delete-favourite');

if(deletefavourite) {
    deletefavourite.addEventListener('click', function() {
        document.cookie = "gjs_ids_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        favouriteNotis.classList.remove('active');

        checkboxesInfavourite.forEach(cb => {
            const product = cb.parentNode.parentNode;
            product.classList.add('hide');
            setTimeout(() => {
                product.classList.add('hidden');
            }, 1500);
        });
        
        setTimeout(() => {
            deletefavourite.classList.add('hidden');
        }, 2000);
    });
}

function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

function checkCookie() {
    const ids_cookie = getCookie('gjs_ids_cookie');
    if( ids_cookie != null && ids_cookie != undefined && ids_cookie != '' ) {
        checkboxIds = JSON.parse(ids_cookie);

        if( checkboxIds != '' ) {
            favouriteNotis.classList.add('active');
        }
    } else {
        checkboxIds = [];
    }

    if( checkboxIds.length > 9 ) {
        favouriteNumber.innerHTML = '9+';
    } else {
        favouriteNumber.innerHTML = checkboxIds.length;
    }
}

document.addEventListener("DOMContentLoaded", checkCookie);



function setCookie(cName, checkboxId, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    const removeIndex = checkboxIds.indexOf(checkboxId);

    if (removeIndex > -1) {
        checkboxIds.splice(removeIndex, 1);
        document.cookie = cName + "=" + JSON.stringify(checkboxIds) + "; " + expires + "; path=/";
        if( checkboxIds == '' ) {
            favouriteNotis.classList.remove('active');
        }
    } else {
        checkboxIds.push(checkboxId);
        document.cookie = cName + "=" + JSON.stringify(checkboxIds) + "; " + expires + "; path=/";
    }

    if( checkboxIds != '' ) {
        favouriteNotis.classList.add('active');
    }

    if( checkboxIds.length > 9 ) {
        favouriteNumber.innerHTML = '9+';
    } else {
        favouriteNumber.innerHTML = checkboxIds.length;
    }

    favouriteNotis.classList.add('expand');
    setTimeout(() => {
        favouriteNotis.classList.remove('expand');
    }, 300);
}

const checkboxes = document.querySelectorAll('.favourite-product-checkbox');
document.body.addEventListener('click', function(e){
    if( e.target.matches('.favourite-product-checkbox') ){
        setCookie('gjs_ids_cookie', e.target.id, 30);
    }
});