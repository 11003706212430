const sliders = document.querySelectorAll('.side-scroll, .upsells .products, .related .products');
let isDown = false;
let startX;
let scrollLeft;


sliders.forEach(slider => {
  slider.addEventListener('mousedown', e => {
    console.dir(e);
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
});
  
sliders.forEach(slider => {
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
});

sliders.forEach(slider => {
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
});

sliders.forEach(slider => {
  slider.addEventListener('mousemove', e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;
    slider.scrollLeft = scrollLeft - walk;
  });
});