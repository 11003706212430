const siteNavItemsArray = document.querySelectorAll('.site-nav-item');
const menuContainer = document.querySelector('#menu-container');
const fixedMenu = document.querySelector('.fixed-menu');

if( fixedMenu ) {

    if( menuContainer ) {
        const siteNavItems = [...siteNavItemsArray].map(siteNavItem => {
            if( siteNavItem.id.length > 0 ) {
                return /*html*/`<span><a class="anc nav-link" href="#${siteNavItem.id}">${siteNavItem.textContent}</a></span>`;
            }
        }).join('');
        menuContainer.innerHTML = siteNavItems;
    }
    
    // FIXED SITE NAV PÅ SRKOLL

    const mainMenu = document.querySelector('#masthead');
    mainMenuHeight = mainMenu.offsetHeight;
    const menuFixed = fixedMenu.offsetTop - mainMenuHeight;

    function addFixedOnNavOnScroll() {
        if ( window.innerWidth < 768 ) {
            if (window.pageYOffset >= menuFixed + 50) {
                fixedMenu.classList.add("fixed");
            } else {
                fixedMenu.classList.remove("fixed");
            }
        } else {            
            if (window.pageYOffset >= menuFixed) {
                fixedMenu.classList.add("fixed");
            } else {
                fixedMenu.classList.remove("fixed");
            }
        }
    }

    window.addEventListener('load', addFixedOnNavOnScroll);
    window.addEventListener('scroll', addFixedOnNavOnScroll);
    
 
(function ($) {
    var lastId,
      topMenu = $("#menu-container"),
      topMenuHeight = topMenu.outerHeight() + 80,
      // All list items
      menuItems = $('.nav-link'),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function () {
        var item = $($(this).attr("href"));
        if (item.length) {
          return item;
        }
      });

    // Bind click handler to menu items
    // so we can get a fancy scroll animation
    menuItems.on('click', function (e) {
      var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
        
      $('html, body').stop().animate({
        scrollTop: offsetTop
      }, 300);
      e.preventDefault();
    });

    // Bind to scroll
    $(window).on('scroll', function () {
      // Get container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight;

      // Get id of current scroll item
      var cur = scrollItems.map(function () {
        if ($(this).offset().top < fromTop)
          return this;
      });
      // Get the id of the current element
      cur = cur[cur.length - 1];
      var id = cur && cur.length ? cur[0].id : "";

      if (lastId !== id) {
        lastId = id;

        menuItems
          .parent().removeClass("active")
          .end().filter("[href='#" + id + "']").parent().addClass("active");
      }
    });

    })(jQuery); // Fully reference jQuery after this point.
}

