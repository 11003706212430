const Flickity = require('flickity');
const FlickityFullscreen = require('flickity-fullscreen');
const blockCarousels = document.querySelectorAll('.block-carousel-slider');
if (blockCarousels) {

    blockCarousels.forEach(bc => {       
        new Flickity(bc, {
            contain: true, 
            fullscreen: true,
            watchCSS: true,
            draggable: '>1',
            wrapAround: false,
            freeScroll: true,
            pageDots: false,
            cellAlign: 'center',
            // groupCells : 2
            // autoPlay: 2000,
            // selectedAttraction: 0.00001,
            on: {
                staticClick: function( event, pointer, cellElement, cellIndex) {
                    const fullscreenBtn = bc.querySelector('.flickity-fullscreen-button');
                        if ( !cellElement ) {
                            return;
                        }
                        fullscreenBtn.click();    
                },
            }
        });
    });
}

document.body.addEventListener('click', function(e){
    if ( e.target.matches('.lightbox-img img') ) {
        const fullsizeImg = document.querySelector('.fullsize-img');
        const imgSrc = e.target.dataset.fullsize;
        fullsizeImg.setAttribute('src', imgSrc);
    }
})