const requestBtns = document.querySelectorAll('.models-inquery');
const rTitle = document.querySelector('#r-title');
const rArtnr = document.querySelector('#r-artnr');

if( requestBtns ) {

    requestBtns.forEach(requestBtn => requestBtn.addEventListener('click', function(e) {
        const modelName = requestBtn.dataset.modelname;
        const modelNr = requestBtn.dataset.modelnr;
        rTitle.value = modelName;
        rArtnr.value = modelNr;
    }));

}

const bootstrap = require('bootstrap');
const submit = document.querySelector('#r-submit');
const requestModal = document.querySelector('#requestModal');
const modal = new bootstrap.Modal(requestModal);
const supportCf7Wrapper = document.querySelector('#requestModal .wpcf7');
const supportCf7 = document.querySelector('#requestModal .wpcf7 .wpcf7-form');

if( submit ) {
    supportCf7Wrapper.addEventListener(
        'wpcf7mailsent',
        function(event) {
            setTimeout(() => {
                requestModal.classList.add('show-output');
            }, 500);
            
            setTimeout(() => {
                modal.hide();
                requestModal.classList.remove('show-output');
                supportCf7.classList.remove('sent');
                supportCf7.classList.add('init');
            }, 3000);
        },
        false
    );
}