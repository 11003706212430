const lang = document.documentElement.lang
const langEnd = lang.substring(lang.indexOf('-', ''))
const currentLang = lang.replace(langEnd, '')
const cartQty = document.querySelector('#cart-qty')

function plusMinus(e) {
    const qytInput = document.querySelector('.input-text.qty')
    let qty = parseInt(qytInput.value)

    if (e.target.matches('.plus')) {
        qytInput.value = qty + 1
    } else if (e.target.matches('.minus') && qty > 1) {
        qytInput.value = qty - 1
    }
}

document.addEventListener('click', function (e) {
    if (e.target.matches('.plusminus')) {
        plusMinus(e)
    }

    if (e.target.matches('.close-notice')) {
        document.body.classList.remove('show-notice')
    }

    if (e.target.matches('.close-cart')) {
        e.target.parentNode.parentNode.classList.remove('active')
        document.querySelector('.toggle-el-overlay').remove()
    }
})

const noticesWrapper = document.querySelector('.woocommerce-notices-wrapper')
if (noticesWrapper) {
    noticesWrapper.insertAdjacentHTML('afterbegin', `<div class="close-notice">&times</div>`)
}

;(function ($) {
    $(document.body).on('adding_to_cart', function () {
        document.body.classList.add('cart-loading')
    })

    $(document.body).on('updated_wc_div', function () {
        if (noticesWrapper.childElementCount > 1) {
            document.body.classList.add('show-notice')
        } else {
            document.body.classList.remove('show-notice')
        }
    })

    $(document.body).trigger('found_variation', function () {
        console.log([variation])
    })

    $(document.body).on('added_to_cart', function () {
        document.body.classList.remove('cart-loading')
        document.body.classList.add('cart-added')

        setTimeout(() => {
            document.body.classList.remove('cart-added')
        }, 500)
    })

    $(document).on('click', '.single_add_to_cart_button, .product_type_simple.add_to_cart_button', function (e) {
        e.preventDefault()

        var addToCartBtn = $(this),
            form = addToCartBtn.closest('form.cart'),
            id = addToCartBtn.val(),
            productQty = form.find('input[name=quantity]').val() || 1,
            productId = form.find('input[name=product_id]').val() || id,
            variationId = form.find('input[name=variation_id]').val() || 0

        var ajaxData = {
            action: 'add_to_cart',
            security: lwGlobal.addToCart,
            product_id: productId,
            product_sku: '',
            quantity: productQty,
            variation_id: variationId,
        }

        $(document.body).trigger('adding_to_cart', [addToCartBtn, ajaxData])

        $.ajax({
            type: 'post',
            url: lwGlobal.adminUrl,
            data: ajaxData,

            beforeSend(response) {
                document.body.classList.add('cart-loading')
                if (currentLang === 'en') {
                    addToCartBtn[0].innerHTML = 'Adding product'
                } else {
                    addToCartBtn[0].innerHTML = 'Lägger till produkt'
                }
            },
            success(response, statusTxt) {
                $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, addToCartBtn])
                document.body.classList.remove('cart-loading')
                document.body.classList.add('cart-added')

                if (currentLang === 'en') {
                    addToCartBtn[0].innerHTML = 'Product added'
                } else {
                    addToCartBtn[0].innerHTML = 'Produkt tillagd'
                }

                if (statusTxt === 'success') {
                    setTimeout(() => {
                        document.body.classList.remove('cart-added')
                        addToCartBtn.removeClass('added')

                        if (currentLang === 'en') {
                            addToCartBtn[0].innerHTML = 'Add to cart'
                        } else {
                            addToCartBtn[0].innerHTML = 'Lägg i varukorg'
                        }
                    }, 500)
                }
            },
            error(error) {
                console.log('error', error)
            },
        })

        return false
    })

    let timeout

    $(document).on('click', '.cart-plusminus', function (e) {
        e.preventDefault()
        const qytInput = e.target.parentNode.previousElementSibling
        let qty = parseInt(qytInput.value),
            productQty,
            action,
            security

        if (e.target.matches('.cart-item-remove')) {
            action = 'remove_from_cart'
            security = lwGlobal.removeFromCart
            e.target.parentNode.style.opacity = '0.3'
        } else if (e.target.matches('.cart-plus')) {
            productQty = qty + 1
            qytInput.value = qty + 1
            action = 'deacrease_cart_item'
            security = lwGlobal.deacreaseCartItem
        } else if (e.target.matches('.cart-minus') && qty > 1) {
            productQty = qty - 1
            qytInput.value = qty - 1
            action = 'deacrease_cart_item'
            security = lwGlobal.deacreaseCartItem
        } else {
            qytInput.value = qty - 1
            action = 'remove_from_cart'
            security = lwGlobal.removeFromCart
        }

        var handleCartBtn = $(this),
            productId = e.target.parentNode.dataset.id,
            variationId = e.target.parentNode.dataset.variation || 0

        var ajaxData = {
            action: action,
            security: security,
            product_id: productId,
            product_sku: '',
            quantity: productQty,
            variation_id: variationId,
        }

        if (timeout !== undefined) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            $(document.body).trigger('adding_to_cart', [handleCartBtn, ajaxData])
            $.ajax({
                type: 'post',
                url: lwGlobal.adminUrl,
                data: ajaxData,

                beforeSend(response) {
                    document.body.classList.add('cart-loading')
                },
                success(response, statusTxt) {
                    $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, handleCartBtn])

                    if (statusTxt === 'success') {
                        document.body.classList.remove('cart-loading')
                    }
                },
                error(error) {
                    console.log(error)
                },
            })
        }, 500)

        return false
    })
})(jQuery)

function changeSelectFirstValue() {
    const variations = document.querySelectorAll('table.variations tr')
    variations.forEach((variation) => {
        let stringToReplace
        const label = variation.querySelector('.label label')
        const select = variation.querySelector('.value select')
        if (currentLang === 'en') {
            stringToReplace = select.options[0].innerHTML.replace('an option', label.innerHTML)
            select.options[0].innerHTML = stringToReplace
        } else stringToReplace = select.options[0].innerHTML.replace('ett alternativ', label.innerHTML)
        select.options[0].innerHTML = stringToReplace
    })
}
document.addEventListener('DOMContentLoaded', changeSelectFirstValue)
