const yt_player = document.querySelector('#yt_player');
const videoBtn = document.querySelector('.watch-video-btn');

if( yt_player ) {
    // Load the IFrame Player API code asynchronously.
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const youtubeId = yt_player.dataset.video;

    // Replace the 'ytplayer' element with an <iframe> and
    // YouTube player after the API code downloads.

    var player, fullYtPlayer;
    function onYouTubePlayerAPIReady() {

        player = new YT.Player('yt_player', {
            videoId: youtubeId,
            height: '500',
            width: '640',
            playerVars: {
                'autoplay': 1,        // Auto-play the video on load
                'controls': 0,        // Show pause/play buttons in player
                'showinfo': 0,        // Hide the video title
                'modestbranding': 1,  // Hide the Youtube Logo
                'loop': 1,            // Run the video in a loop
                'fs': 0,              // Hide the full screen button
                'cc_load_policy': 0,  // Hide closed captions
                'iv_load_policy': 3,  // Hide the Video Annotations
                'autohide': 0,        // Hide video controls when playing
                'playsinline': 1
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
        
        fullYtPlayer = new YT.Player('full_player', {
            videoId: youtubeId,
            height: '500',
            width: '640',
            playerVars: {
                'autoplay': 0,        // Auto-play the video on load
                'controls': 1,        // Show pause/play buttons in player
                'showinfo': 1,        // Hide the video title
                'modestbranding': 1,  // Hide the Youtube Logo
                'loop': 0,            // Run the video in a loop
                'fs': 1,              // Hide the full screen button
                'cc_load_policy': 0,  // Hide closed captions
                'iv_load_policy': 3,  // Hide the Video Annotations
                'autohide': 0,        // Hide video controls when playing
                'playsinline': 1
            },
            events: {
                'onReady': onFullPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
    }

    function onFullPlayerReady(e) {
        document.body.addEventListener('click', function(e) {
            if ( e.target.matches('#videoModal') ) {
                fullYtPlayer.pauseVideo();
                player.playVideo();
            }
        });
    }

    function onPlayerReady(e) {
        e.target.mute();
        e.target.playVideo();

        document.body.addEventListener('click', function(e) {
            if ( e.target.matches('#watch-youtube') ) {
                player.pauseVideo();
                fullYtPlayer.playVideo();
            }
        });
    }
    
    
    function onPlayerStateChange(e) {
        if (e.data == 0 ) {
            e.target.playVideo();
        }
    }

    

    window.addEventListener("load", onYouTubePlayerAPIReady);
}

const reg_player = document.querySelector('#reg_player');
const productHeader = document.querySelector('.single-product-header');
const closeVideoBtn = document.querySelector('.close-video-btn');
const fullPlayer = document.querySelector('#full_player');

if( reg_player ) {
    let notPlaying = false;

    function loopVid() {
        if( notPlaying ) {
            return;
        } else {
   
            setTimeout(function() {
                reg_player.currentTime = 0;
                loopVid();
            }, 4000);
            
        }

    }

    loopVid();
}

function playVideo(e) {
    fullPlayer.innerHTML = reg_player.innerHTML;
    fullPlayer.play();
    fullPlayer.controls = true;
    notPlaying = true;
    reg_player.pause();
    
    // productHeader.classList.add('play-video');
    // reg_player.muted = false;
    // reg_player.autoplay = false;
    // reg_player.loop = false;
    // reg_player.currentTime = 0;
    // reg_player.play();
    // reg_player.controls = true;
};

function closeVideo(e) {
    // productHeader.classList.remove('play-video');
    fullPlayer.pause();

    reg_player.muted = true;
    reg_player.autoplay = true;
    reg_player.loop = true;
    reg_player.controls = false;
    reg_player.play();
    notPlaying = false;
};

// closeVideoBtn.addEventListener('click', closeVideo)

// videoBtn.addEventListener('click', playVideo);

document.body.addEventListener('click', function(e) {
    if ( e.target.matches('#watch-reg-video') ) {
        playVideo();
    }
    
    if ( e.target.matches('#videoModal') || e.target.matches('.close-video-modal')) {
        closeVideo();
    }
});

document.body.addEventListener('keyup', function(e){
    if ( e.code === 'Escape' ) {
        if ( yt_player || reg_player ) {
            closeVideo();
        }
    }
});