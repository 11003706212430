const navTabs = document.querySelectorAll('.tabs-wrapper .btn');
const tabs = document.querySelectorAll('.tab-mobile');

if ( navTabs ) {

    navTabs.forEach(navTab => navTab.addEventListener('click', function(){
        const tabFor = this.dataset.navTab;
        const parent = this.parentNode;
        const allTabs = parent.querySelectorAll('.btn');
        allTabs.forEach(allTab => {
            allTab.classList.remove('active');
        });
        this.classList.add('active');
        
        tabs.forEach(tab => {
            tab.classList.remove('active');

            if ( tabFor === tab.dataset.tab ) {
                tab.classList.add('active');
            }
        });
    }));

}