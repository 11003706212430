let loading = false

document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('click', function (e) {
        const target = e.target

        // Hantera klick för att filtrera produkter
        if (target.closest('.woo-filter-item') || target.closest('.widget_layered_nav_filters .chosen a')) {
            e.preventDefault()
            getProducts(target.href)
        }

        // Toggla filter-menyns synlighet
        if (target.matches('.woo-filter-btn')) {
            document.body.classList.toggle('filter-active')
        }

        // Stäng filter-menyn
        if (target.matches('.filter-overlay, .close-filter') || target.matches('.close-filters')) {
            document.body.classList.remove('filter-active')
        }

        // Hantera dropdowns inom filter-wrapper
        if (target.closest('#filter-wrapper > li.widget_layered_nav')) {
            const parent = target.closest('#filter-wrapper > li.widget_layered_nav')
            parent.classList.toggle('filter-active')
        } else {
            // Stäng alla filter-dropdowns om klicket är utanför
            document.querySelectorAll('#filter-wrapper > li.widget_layered_nav.filter-active').forEach((targ) => {
                targ.classList.remove('filter-active')
            })
        }
    })

    // Hantera "ladda mer"-funktionalitet vid scroll
    window.addEventListener('scroll', debounce(loadMoreProducts, 100))
})

function getProducts(href) {
    loading = true
    document.querySelectorAll('.product').forEach((product) => (product.style.opacity = '0.5'))

    fetch(href, { mode: 'cors' })
        .then((response) => response.text())
        .then((data) => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(data, 'text/html')

            window.history.pushState({}, '', href)
            ;[
                '#products-loop',
                '.filter-sidebar-inner',
                '.woocommerce-result-count',
                '.woocommerce-ordering',
                '.load-more-anchor-wrapper',
                '.woocommerce-pagination',
            ].forEach((selector) => {
                updateContent(selector, doc)
            })

            // Återställ current_page till 1 för att starta om pagineringen med det nya filtret
            updateLoadMoreAnchor()
        })
        .catch((error) => {
            console.error('Error:', error)
        })
        .finally(() => {
            loading = false
        })
}

function updateContent(selector, sourceDoc) {
    const sourceElement = sourceDoc.querySelector(selector)
    const destinationElement = document.querySelector(selector)
    if (sourceElement && destinationElement) {
        destinationElement.innerHTML = sourceElement.innerHTML
    }
}

function loadMoreProducts() {
    let loadMoreAnchor = document.querySelector('.load-more-anchor')
    if (!loadMoreAnchor || loading) return

    const { top } = loadMoreAnchor.getBoundingClientRect()
    const offset = 300
    if (window.innerHeight + window.scrollY >= top + window.scrollY - offset) {
        loadProducts(loadMoreAnchor)
    }
}

function loadProducts(loadMoreAnchor) {
    const maxNumPages = parseInt(loadMoreAnchor.dataset.max_num_pages, 10)
    const currentPage = parseInt(loadMoreAnchor.dataset.current_page, 10)

    if (loading || maxNumPages <= currentPage) {
        loadMoreAnchor.querySelector('p').innerText = 'Inga fler produkter att visa'
        return
    }

    const link = document.querySelector('.next.page-numbers')
    if (!link) return
    loading = true
    fetch(link.href, { mode: 'cors' })
        .then((response) => response.text())
        .then((data) => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(data, 'text/html')

            window.history.pushState({}, '', link.href)

            const productContainer = document.querySelector('#products-loop')
            const getProductContainer = doc.querySelector('#products-loop')
            productContainer.insertAdjacentHTML('beforeend', getProductContainer.innerHTML)

            // Uppdatera pagination, antal produkter, och "ladda mer"-ankare
            ;['.woocommerce-pagination', '.woocommerce-result-count', '.load-more-anchor-wrapper'].forEach(
                (selector) => {
                    updateContent(selector, doc)
                }
            )

            // Uppdatera 'loadMoreAnchor' med nya data-attribut
            updateLoadMoreAnchorAfterLoading(doc)
        })
        .catch((error) => {
            console.error('Error:', error)
        })
        .finally(() => {
            loading = false
        })
}

function updateLoadMoreAnchor() {
    const loadMoreAnchor = document.querySelector('.load-more-anchor')
    if (loadMoreAnchor) {
        loadMoreAnchor.dataset.current_page = '1'
    }
}

function updateLoadMoreAnchorAfterLoading(doc) {
    let loadMoreAnchor = document.querySelector('.load-more-anchor')
    const newLoadMoreAnchor = doc.querySelector('.load-more-anchor')
    if (loadMoreAnchor && newLoadMoreAnchor) {
        loadMoreAnchor.dataset.current_page = newLoadMoreAnchor.dataset.current_page
    }
}

function debounce(func, delay) {
    let debounceTimer
    return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
}
