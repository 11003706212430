const megaItems = document.querySelectorAll('.mega-item')
const searchInput = document.querySelector('#search-input')
const searchWrapper = document.querySelector('.site-search-wrapper')
const popularProducts = document.querySelector('.search-popular-products')

function openSearch() {
    searchWrapper.classList.add('active')
    popularProducts.classList.remove('d-none')
    document.querySelector('.toggle-el-overlay').classList.remove('d-none')
    document.body.classList.remove('hamburger-active', 'show-cart')
    megaItems.forEach((megaItem) => megaItem.classList.remove('active'))
    searchInput.focus()
}

searchInput.addEventListener('keyup', () => {
    if (searchInput.value.length > 0) {
        popularProducts.classList.add('d-none')
    } else {
        popularProducts.classList.remove('d-none')
    }
})

searchInput.addEventListener('click', openSearch)

document.addEventListener('click', (e) => {
    if (e.target.closest('.search-btn')) {
        openSearch()
    }

    if (e.target.closest('.toggle-el-overlay') || e.target.closest('#close-search-result')) {
        closeSearch()
    }
})

export function closeSearch() {
    searchWrapper.classList.remove('active')
    popularProducts.classList.add('d-none')
    document.querySelector('.toggle-el-overlay').classList.add('d-none')
    searchInput.value = ''
    return
}
