document.addEventListener("click", (e) => {
  if (e.target.matches(".dp")) {
    const ds = e.target.nextElementSibling;
    if (ds.classList.contains("active")) {
      ds.classList.remove("active");
      e.target.classList.remove("active");
    } else {
      ds.classList.add("active");
      e.target.classList.add("active");
    }
  }

  if (e.target.matches(".anc") || e.target.matches(".anc a")) {
    // location.hash = e.target.hash;
    e.preventDefault();
    let hash = e.target.getAttribute("href");
    let target = document.querySelector(hash);
    let headerOffset = 130;
    let elementPosition = target.offsetTop;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
});

window.addEventListener("load", () => {
  document
    .querySelectorAll(".spinner-border, .spinner-grow, .lw-loader")
    .forEach((spinner) => {
      spinner.remove();
    });

  document.querySelectorAll(".hide-on-load").forEach((hideOnLoad) => {
    hideOnLoad.classList.remove("hide-on-load");
  });
});
