/**
 * File theme.js.
 */
import { closeSearch } from "./search.js";

// FIXED HEADER PÅ SCROLL
function addFixedOnScroll() {
  if (window.innerWidth < 768) {
    if (window.pageYOffset >= 85) {
      document.body.classList.add("header-fixed");
    } else {
      document.body.classList.remove("header-fixed");
    }
  } else {
    if (window.pageYOffset >= 30) {
      document.body.classList.add("header-fixed");
    } else {
      document.body.classList.remove("header-fixed");
    }
  }
}

window.addEventListener("load", addFixedOnScroll);
window.addEventListener("scroll", addFixedOnScroll);

// TOGGLAR SIDEBAR PÅ MOBIL
// const toggleSidebar = document.querySelector("#toggle-sidebar");
// if (toggleSidebar) {
//   toggleSidebar.addEventListener("click", function () {
//     document.body.classList.toggle("sidebar-open");
//   });
// }

// const langSwitcher = document.querySelector("#lang-switcher");

// langSwitcher.addEventListener("click", () => {
//   document.body.classList.toggle("lang-open");
// });

// const topProfile = document.querySelector("#top-profile.top-profile");

// if (topProfile) {
//   topProfile.addEventListener("click", () => {
//     document.body.classList.toggle("profile-open");
//   });
// }

// const currencySwitcher = document.querySelector('#currency-switcher');

// currencySwitcher.addEventListener('click', ()=> {
//     document.body.classList.toggle('curr-open');
// });

document.addEventListener("click", function (e) {
  if (e.target.closest("#currency-switcher")) {
    document.body.classList.toggle("curr-open");
  }

  if (e.target.closest("#lang-switcher")) {
    document.body.classList.toggle("lang-open");
  }

  if (e.target.closest("#top-profile")) {
    document.body.classList.toggle("profile-open");
  }

  if (e.target.closest("#toggle-sidebar")) {
    document.body.classList.toggle("sidebar-open");
  }
});
