const modelsBtns = document.querySelectorAll('.models-btn')
if (modelsBtns) {
    modelsBtns.forEach((modelsBtn) =>
        modelsBtn.addEventListener('click', () => {
            if (document.body.classList.contains('models-dropdown-active')) {
                document.body.classList.remove('models-dropdown-active')
            } else {
                document.body.classList.add('models-dropdown-active')
            }
        })
    )
}

const showMoreWrappers = document.querySelectorAll('.show-more-wrapper')

if (showMoreWrappers) {
    showMoreWrappers.forEach((showMoreWrapper) => {
        window.addEventListener('load', () => {
            const height = showMoreWrapper.offsetHeight
            if (height > 150) {
                showMoreWrapper.classList.add('too-high')
            }
        })
    })
}

const showMoreBtns = document.querySelectorAll('.show-more')
function showMore() {
    const parent = this.parentNode
    if (parent.classList.contains('higher')) {
        parent.classList.remove('higher')
    } else {
        parent.classList.add('higher')
    }
}

showMoreBtns.forEach((showMoreBtn) => showMoreBtn.addEventListener('click', showMore))

document.addEventListener('click', function (e) {
    if (e.target.matches('.side-drop')) {
        const drop = e.target
        drop.parentNode.classList.toggle('active')

        setTimeout(() => {
            drop.parentNode.classList.toggle('show')
        }, 100)
    }
})

const subs = document.querySelectorAll('.has-sub')
if (subs) {
    subs.forEach((sub) => {
        const subItems = sub.querySelectorAll('.side-item')

        subItems.forEach((subItem) => {
            if (subItem.classList.contains('current-item')) {
                sub.classList.add('active', 'show')
            }
        })
    })
}

const forgotPassLink = document.querySelector('#loginModal .lost_password a')
const forgotPassWrapper = document.querySelector('#forgot-pass-wrapper')

forgotPassLink.addEventListener('click', function (e) {
    e.preventDefault()
    forgotPassWrapper.classList.toggle('show')
})

const loginModal = document.querySelector('#loginModal')
const registerBtn = loginModal.querySelector('.reg-btn')
const loginHeading = loginModal.querySelector('.login-login h2')

registerBtn.addEventListener('click', function (e) {
    loginModal.classList.add('show-reg')
})

loginHeading.addEventListener('click', function (e) {
    loginModal.classList.remove('show-reg')
})

// var select = document.getElementById('pa_storlek')

// if (select) {
//     var options = Array.from(select.options)

//     options.sort(function (a, b) {
//         // Konvertera texten till decimaltal för att jämföra
//         var sizeA, sizeB
//         if (a.text.startsWith('Ø ')) {
//             sizeA = parseFloat(a.text.replace('Ø ', '').replace(',', '.'))
//             sizeB = parseFloat(b.text.replace('Ø ', '').replace(',', '.'))
//         } else if (a.text.startsWith('Ø')) {
//             sizeA = parseFloat(a.text.replace('Ø', '').replace(',', '.'))
//             sizeB = parseFloat(b.text.replace('Ø', '').replace(',', '.'))
//         } else if (a.text.startsWith('M')) {
//             sizeA = parseFloat(a.text.replace('M', '').replace(',', '.'))
//             sizeB = parseFloat(b.text.replace('M', '').replace(',', '.'))
//         } else if (a.text.startsWith('W')) {
//             sizeA = parseFloat(a.text.replace('W', '').replace(',', '.'))
//             sizeB = parseFloat(b.text.replace('W', '').replace(',', '.'))
//         }

//         // Sortera i stigande ordning med korrekt ordning för 0-9 och 10+
//         if (sizeA < sizeB) {
//             return -1
//         } else if (sizeA > sizeB) {
//             return 1
//         } else {
//             return 0
//         }
//     })

//     // Ta bort befintliga options från select
//     while (select.firstChild) {
//         select.removeChild(select.firstChild)
//     }

//     // Lägg till de sorterade options igen
//     options.forEach(function (option) {
//         select.appendChild(option)
//     })

//     select.selectedIndex = 0
// }
