const Flickity = require('flickity');
const FlickityFullscreen = require('flickity-fullscreen');
const FlickityFade = require('flickity-fade');

const carousels = document.querySelectorAll('.carousel');

if (carousels) {
    carousels.forEach(carousel => {    
        new Flickity(carousel, {
            contain: true, 
            fullscreen: true,
            watchCSS: true,
            freeScroll: true,
            draggable: '>1',
            wrapAround: false,
            setGallerySize: false,
            percentPosition: false,
            pageDots: false,
            // groupCells : 2,
            // autoPlay: 2000,
            // selectedAttraction: 0.00001,
        });

        // const fullscreenBtn = carousel.querySelector('.flickity-fullscreen-button');
        // const slides = carousel.querySelectorAll('.carousel-image');
        // const viewPort = carousel.querySelector('.flickity-viewport');

        // slides.forEach(slide => slide.addEventListener('click', ()=> {
        //     fullscreenBtn.click();
        // }));
    });
}

const fullSliders = document.querySelectorAll('.full-slider');
if (fullSliders) {
    fullSliders.forEach(fullSlider => {    
        new FlickityFullscreen(fullSlider, {
            contain: true, 
            fullscreen: false,
            watchCSS: true,
            freeScroll: false,
            draggable: false,
            wrapAround: true,
            setGallerySize: false,
            percentPosition: false,
            pageDots: false,
            autoPlay: 6000,
            // groupCells : 2,
            // selectedAttraction: 0.00001,
        });
    });
}

const cleanSliders = document.querySelectorAll('.clean-slider');
if (cleanSliders) {
    cleanSliders.forEach(cleanSlider => {
        const slides = cleanSlider.querySelectorAll('.img-wrapper');
        new FlickityFullscreen(cleanSlider, {
            contain: true, 
            fullscreen: false,
            freeScroll: false,
            draggable: '>1',
            wrapAround: true,
            setGallerySize: true,
            percentPosition: true,
            pageDots: false,
            autoPlay: false,
            // selectedAttraction: 0.00001,

            on: {
                ready: function() {
                    slides.forEach(slide => {
                        slide.style.height = cleanSlider.offsetHeight + 'px';
                    });
                    cleanSlider.classList.add('inherit', 'add-width');
                },
            }
        });
    });
}

const fullSliderDots = document.querySelectorAll('.full-slider-dots');
if (fullSliderDots) {
    fullSliderDots.forEach(fullSliderDot => {    
        new FlickityFade(fullSliderDot, {
            contain: true,
            fullscreen: false,
            watchCSS: true,
            freeScroll: false,
            draggable: false,
            wrapAround: true,
            // setGallerySize: false,
            percentPosition: false,
            pageDots: true,
            prevNextButtons: false,
            fade: true,
            autoPlay: 6000,
            // groupCells : 2,
            // selectedAttraction: 0.00001,
        });
    });
}

const upSellCarousels = document.querySelectorAll('.is-desktop .upsells .products, .is-desktop .related .products');
if (upSellCarousels) {
    upSellCarousels.forEach(upCarousel => {
        const slides = upCarousel.querySelectorAll('li.product');

        new Flickity(upCarousel, {
            watchCSS: true,
            contain: true, 
            freeScroll: true,
            draggable: '>1',
            wrapAround: false,
            fullscreen: false,
            lazyLoad: true,
            // setGallerySize: false,
            percentPosition: false,
            pageDots: false,
            groupCells : true,
            cellAlign: 'left',
            // autoPlay: 2000,
            // selectedAttraction: 0.00001,

            on: {
                ready: function() {
                    slides.forEach(slide => {
                        slide.style.height = upCarousel.offsetHeight + 'px';
                    });
                    upCarousel.classList.add('inherit', 'add-width');
                },
            }
        });
    });
}

const sliders = document.querySelectorAll('.slider, .use-as-slider #products-loop, .mobile-slider');
if (sliders) {
    sliders.forEach(slider => {
        const slides = slider.querySelectorAll('.card-item');
        
        new Flickity(slider, {
            watchCSS: true,
            contain: true, 
            freeScroll: true,
            draggable: '>1',
            wrapAround: false,
            fullscreen: false,
            // setGallerySize: false,
            percentPosition: false,
            pageDots: false,
            groupCells : true,
            cellAlign: 'left',
            // autoPlay: 2000,
            // selectedAttraction: 0.00001,

            on: {
                ready: function() {
                    slides.forEach(slide => {
                        slide.style.height = slider.offsetHeight + 'px';
                    });
                    slider.classList.add('inherit', 'add-width');
                },
            }
        });
    });
}

(function() {
    var touchingCarousel = false,
        touchStartCoords;

    document.body.addEventListener('touchstart', function(e) {
        if (e.target.closest('.flickity-slider')) {
            touchingCarousel = true;
        } else {
            touchingCarousel = false;
            return;
        }

        touchStartCoords = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY,
        };
    });

    document.body.addEventListener(
        'touchmove',
        function(e) {
            if (!(touchingCarousel && e.cancelable)) {
                return;
            }

            var moveVector = {
                x: e.touches[0].pageX - touchStartCoords.x,
                y: e.touches[0].pageY - touchStartCoords.y,
            };

            if (Math.abs(moveVector.x) > 7) e.preventDefault();
        },
        { passive: false }
    );
})();